.jumbotron {
  background: none;
}

#extrasTitle {
  margin-top: 20px;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 60px;
  font-size: 75px;
}

#extrasJumbotron {
  padding-top: 0;
}

@media only screen and (max-width: 700px, max-height: 500px) {
  #extrasTitle {
    font-size: 40px;
    margin-top: 10px;
  }
}
