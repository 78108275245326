#githubLink {
  color: gray;
}
#githubLink:hover {
  color: white;
}

#linkedInLink {
  color: gray;
}
#linkedInLink:hover {
  color: white;
}

#emailLink {
  color: gray;
}
#emailLink:hover {
  color: white;
}

a {
  text-decoration: none;
}

#emailContact {
  font-size: 60px;
}

#socialLinks {
  font-size: 60px;
  padding-top: 0;
}

#resumeLink {
  color: gray;
}
#resumeLink:hover {
  color: white;
}
#contactWrapper {
  padding-top: 60px;
}

@media only screen and (max-width: 700px) {
  #emailContact {
    font-size: 30px;
  }
  #contactWrapper {
    padding-top: 40px;
  }

  #socialLinks {
    font-size: 30px;
    padding-top: 0;
  }
}
