body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  /* background-image: url("./images/black-background2.jpg"); */
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 24px;
}

li {
  font-size: 20px;
}

.jumbotron-fluid {
  padding-bottom: 0;
}

a:hover {
  text-decoration: none;
}

img {
  border-color: black;
}
