#homeText {
  padding-top: 220px;
  text-align: center;
  height: 300px;
  font-size: 70px;
}

#homeText2 {
  color: gray;
  font-size: 60px;
}

@media only screen and (max-width: 700px) {
  #homeText {
    padding-top: 35px;
    font-size: 35px;
    height: 85px;
  }
  #homeText2 {
    font-size: 30px;
    padding-bottom: 20px;
    margin-bottom: 15px;
  }

  #homeImage {
    font-size: 15px;
    text-align: center;
    padding: 15px;
    margin: 5px;
  }

  #homeTextDiv {
    font-size: 45px;
    position: absolute;
  }
}
