.navbar {
  background: none;
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 25px;
}

a {
  color: white;
}

#fullImage {
  display: block;
}

#mobileImage {
  display: none;
}
#linkContainer {
  width: 100%;
  justify-content: flex-end;
}
#aboutLink {
  margin-right: 50px;
  margin-left: 45px;
  font-size: 23px;
  text-align: right;
  color: white;
}

#contactLink {
  margin-right: 50px;
  font-size: 23px;
  text-align: right;
  color: white;
}
#extrasLink {
  margin-right: 50px;
  font-size: 23px;
  text-align: right;
  color: white;
}
#worksLink {
  margin-right: 50px;
  font-size: 23px;
  text-align: right;
  color: white;
}

#logoLink {
  margin-left: 0px;

  background-color: black;
}

.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: 0;
  background: white;
  height: 4px;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

#navbarWrapper {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 700px) {
  .navbar {
    background: white;
    margin: 0;
    padding: 10px;
    font-size: 15px;
    height: 90px;
  }
  #linkContainer {
    width: auto;
    justify-content: auto;
  }
  #logoLink {
    background-color: white;
    padding: 0;
    margin: 10px;
  }

  #imageChange {
    src: "../../images/LOGO.png";
  }

  #mobileImage {
    display: block;
  }

  #fullImage {
    display: none;
  }
  #aboutLink {
    font-size: 15px;
    font-weight: bold;
    color: black;
    margin-left: 0;
    margin-right: 10px;
    display: inline-block;
  }
  #contactLink {
    font-size: 15px;
    font-weight: bold;
    color: black;
    margin-right: 10px;
    display: inline-block;
  }
  #extrasLink {
    font-size: 15px;
    font-weight: bold;
    color: black;
    margin-right: 10px;
    display: inline-block;
  }
  #worksLink {
    font-size: 15px;
    font-weight: bold;
    color: black;
    margin-right: 10px;
    display: inline-block;
  }

  #aboutLink:active {
    text-decoration: black;
    text-decoration: underline;
  }

  #contactLink:active {
    text-decoration: black;
    text-decoration: underline;
  }
  #extrasLink:active {
    text-decoration: black;
    text-decoration: underline;
  }


  #worksLink:active {
    text-decoration: black;
    text-decoration: underline;
  }
}
